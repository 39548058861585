$(function() {

    // Set video dimensions
    let windowHeight    = $(window).height();
    let windowWidth     = $(window).width();
    let video           = $(".item-video video");

    if (windowWidth / windowHeight < 1.8) {
        video.css("height", "100vh");
        video.css("width", "auto");
    }else {
        video.css("height", "auto");
        video.css("width", "100%");
    }

    $(".scrollDownWrapper").click(function() {
        $('html, body').animate({
            scrollTop: $("#contact").offset().top - 80
        }, 200);
    });

    $(".navbar-toggler").click(function() {
        if (windowWidth < 576) {
            $(this).find('#nav-icon3').toggleClass('open');
            if(!$(this).hasClass("collapsed")) {
                $("body").css("position", "fixed");
            } else {
                $("body").css("position", "relative");
            }
        }
    });
})
